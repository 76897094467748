export const KEY_GROUP_FILTERS = 'filters';
export const KEY_FILTER = 'filter';
export const TEXT_BRAND = 'Производитель';
export const INFO_TEXT_NOT_FOUND = 'По вашему запросу «{{query}}» ничего&nbsp;не&nbsp;найдено';

export const RESET_LIST = ['offset', 'categories', 'price'];
export const FILTER_TYPE = ['categories', 'price'];
export const AVAILABLE_TYPE = ['BOOLEAN', 'DISTINCT', 'NUM_DISTINCT'];

export const FILTER_BRAND = {
  brands: 'Производитель',
};

export enum FILTER_NAMES {
  price = 'price',
  brands = 'brands',
  categories = 'categories',
  articles = 'articles',
  offset = 'offset',
  sort = 'sort',
}

export const TYPE_QUERIES = {
  sort: 'sort',
  st: 'term',
  offset: 'offset',
};
