import React, { FC, useMemo } from 'react';
import classNames from 'classnames/bind';

import { useNearestSection } from 'pages/CarnicaDSLandings/ui/Sidebar/hooks/useNearestPosition';

import { Logo } from '../../Logo';
import type { ISideBar, TAnchor } from '../../types';
import styles from './styles.pcss';
import { scrollToAnchor } from './utils/scrollToAnchor';

const cx = classNames.bind(styles);

export const SideBarInner: FC<ISideBar & { closeSideBar: () => void }> = ({
  anchors,
  closeSideBar,
  links,
  logoSrc,
  ym,
  analyticsEvents,
}) => {
  const anchorsIds = useMemo(() => {
    return anchors?.map((item) => item.id.split('#').join(''));
  }, [anchors]);

  const activeSection = useNearestSection(anchorsIds);

  const handleClick = (anchor: TAnchor) => () => {
    scrollToAnchor(anchor.id, closeSideBar);
    ym(anchor.title);
    analyticsEvents?.onSidebarLinkClick?.(anchor.title);
  };

  return (
    <nav className={cx('wrapper')}>
      <Logo imgClassName={cx('logo')} link="/customers/products/" logoSrc={logoSrc} />
      <div className={cx('scroll')}>
        <ul className={cx('anchors')}>
          {anchors?.map((anchor) => (
            <li key={anchor.id}>
              <button
                className={cx('anchors__button', {
                  'anchors__button--active': `#${activeSection}` === anchor.id,
                })}
                onClick={handleClick(anchor)}
                type="button"
              >
                {anchor.title}
              </button>
            </li>
          ))}
        </ul>
        {(!!anchors || !!links) && <div className={cx('line')} />}
        <ul className={cx('links')}>
          {links?.map((link) => (
            <li key={link.href}>
              <a
                className={cx('links__item')}
                href={link.href}
                onClick={() => analyticsEvents?.onSidebarLinkClick?.(link.title)}
              >
                {link.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};
