import React, { FC } from 'react';
import { motion } from 'framer-motion';
import { Button } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import { ICON_CHEVRON_LEFT } from 'constructorV1/constants';

import { useCBackButton } from './hooks/useCBackButton';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Кнопка "Назад" в результирующем чеке */
export const CBackButton: FC = () => {
  /** Хук подготовки данных для компонента CBackButton */
  const { isVisible, handleClick } = useCBackButton();

  return isVisible ?
      <motion.section className={cx('wrapper')} layout>
        <Button onClick={handleClick} onlyIcon view="secondary">
          <img alt="" src={ICON_CHEVRON_LEFT} />
        </Button>
      </motion.section>
    : null;
};
