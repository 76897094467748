import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DeviceModal } from '@beef/smart-kit';
import { handleSuccessRequest } from '@beef/layout-kit/utils';
import { setCartData } from '@beef/layout-kit/actions';
import { Provider, connect } from 'react-redux';

import { handleSendAnalytics } from 'utils/analytics/main2021';
import {
  devicePurchaseButtonDataHelper,
  getPurchaseButtonType,
} from 'utils/devicePurchaseButtonDataHelpers';
import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import store from 'store';
import { useDevicePreveiw } from 'utils/hooks/useDevicePreveiw';

import { deviceMapper } from '../../services';

const DevicePreviewDataContent = ({
  slug,
  setSlug,
  cartApi,
  purchasedItemsIds,
  handleSetCartData,
  parent,
}) => {
  const {
    previewType,
    previewData,
    isFetchingPreview,
    isPurchaseLoading,
    isOpenPreview,
    setPreviewData,
    setIsFetchingPreview,
    setIsOpenPreview,
    onClosePreview,
    onPurchase,
    fetchData,
    setPreviewType,
    setDevice,
  } = useDevicePreveiw(cartApi, handleSetCartData);
  const isMobile = useIsMobileDimension();

  const [size, setSize] = useState('l');
  const [isInBasket, setIsInBasket] = useState(false);

  useEffect(() => {
    if (slug?.length > 0) {
      const fetchPreviewData = async () => {
        try {
          setIsOpenPreview(true);
          setIsFetchingPreview(true);

          const data = await fetchData(slug);
          const device = deviceMapper.getEnrichedDevice(data);

          setDevice(device);

          window.dataLayer.push({ ecommerce: null });
          handleSendAnalytics({
            event: 'productClick',
            additionalFields: {
              ecommerce: {
                click: {
                  actionField: { list: parent },
                  products: [
                    {
                      name: data.impression?.name,
                      price: data.impression?.price,
                      brand: data?.impression?.brand,
                      category: data?.impression?.category,
                    },
                  ],
                },
              },
            },
          });

          setPreviewData(device);
          setIsFetchingPreview(false);
        } catch (error) {
          setIsOpenPreview(false);
          setIsFetchingPreview(false);
        }
      };

      fetchPreviewData();
    }
  }, [slug]);

  useEffect(() => {
    const modalSize = isMobile ? 's' : 'l';
    setSize(modalSize);
  }, [isMobile]);

  const onChangeSlug = useCallback((deviceSlug) => {
    setSlug(deviceSlug);
  }, []);

  const onClose = useCallback(() => {
    setSlug('');
    onClosePreview();
  }, []);

  const redirectToCart = useCallback(() => handleSuccessRequest({ redirectToCart: true }), []);

  useEffect(() => {
    const inBasketItemsSet = new Set(purchasedItemsIds);
    const inBasketItem = inBasketItemsSet.has(previewData?.id);
    const purchaseButtonType = getPurchaseButtonType({
      isInBasket: inBasketItem,
      isPreorder: previewData?.isPreorder,
      isDeviceModal: true,
    });
    setIsInBasket(inBasketItem);
    setPreviewType(purchaseButtonType);
  }, [previewData, purchasedItemsIds]);

  const purchaseData = useMemo(
    () =>
      devicePurchaseButtonDataHelper({
        type: previewType,
        onClick: isInBasket ? redirectToCart : onPurchase,
        isLoading: isPurchaseLoading,
      }),
    [previewType, isInBasket, isPurchaseLoading],
  );

  return (
    <DeviceModal
      data={previewData}
      isLoading={isFetchingPreview}
      isOpen={isOpenPreview}
      onChangeColor={onChangeSlug}
      onChangeType={onChangeSlug}
      onClose={onClose}
      purchaseData={purchaseData}
      size={size}
    />
  );
};

DevicePreviewDataContent.propTypes = {
  setSlug: PropTypes.func,
  slug: PropTypes.string,
  parent: PropTypes.string,
  cartApi: PropTypes.func,
  purchasedItemsIds: PropTypes.arrayOf(PropTypes.number),
  handleSetCartData: PropTypes.func,
};

const mapStateToProps = ({ external }) => ({
  cartApi: external?.cartData?.cartApi,
  purchasedItemsIds: external?.cartData?.currentCart?.devices ?? [],
});

const mapDispatchToProps = {
  handleSetCartData: setCartData,
};

export const DevicePreviewInternal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DevicePreviewDataContent);

export const DevicePreview = (props) => (
  <Provider store={store}>
    <DevicePreviewInternal {...props} />
  </Provider>
);
