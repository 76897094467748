import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiGetPresetsUrl } from '../constants';
import { TFetchTariffUpPresetQueryRes, TFetchTariffUpPresetReq } from './types';

export const fetchTariffUpPreset = createAsyncThunk<
  TFetchTariffUpPresetQueryRes,
  TFetchTariffUpPresetReq
>('presetApi/fetchTariffUpPresets', async (params, { signal }) => {
  try {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });
    return (
      await axios.get<TFetchTariffUpPresetQueryRes>(apiGetPresetsUrl, {
        params,
        cancelToken: source.token,
      })
    ).data;
  } catch (e) {
    throw new Error();
  }
});
