import React, { useContext, useMemo } from 'react';
import PropTypes, { string } from 'prop-types';
import { connect } from 'react-redux';
import { Checkbox, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import {
  setOpenAdditionalFiltersModal,
  setParametersAction,
} from 'pages/SearchResultsPage/store/actions';
import { FilterWrapper } from 'pages/SearchResultsPage/components/Filter/FilterWrapper';
import { ShowMoreButton } from 'pages/SearchResultsPage/components/ShowMoreButton';
import { MobileContext } from 'pages/SearchResultsPage/components/Settings/mobileContext';
import { getCurrentFilters, getOpenedFilterType } from 'pages/SearchResultsPage/store/selectors';
import { setTemporaryFilter } from 'pages/SearchResultsPage/store/actions/products';
import { prepareCurrentFilters } from 'pages/SearchResultsPage/utils/helperFilters';
import { FILTER_BRAND } from 'pages/SearchResultsPage/utils/constsFilters';

import { FilterBlockWithAnalytics } from './FilterBlockWithAnalytics';
import styles from './styles.pcss';
import { MAX_CHIPS_VALUE } from '../../constants';

const cx = classNames.bind(styles);

const _FiltersBlock = ({
  filters,
  name,
  setFilters,
  onClick,
  withoutWrapper,
  selectedFilters,
  isMainStep,
  setTempFilter,
  isOpened,
}) => {
  const Wrapper = withoutWrapper ? 'div' : FilterWrapper;
  const title = FILTER_BRAND[name] || name;
  const isMobile = useContext(MobileContext);
  const isAdditionalStep = isMobile && !isMainStep;

  const handleChange = (e) => {
    const newValue = e.target.name || e.target.value;
    const currentFilters = prepareCurrentFilters(newValue, selectedFilters);
    if (isAdditionalStep) {
      setTempFilter(currentFilters);
    } else {
      setFilters(currentFilters.join(';'));
    }
  };

  const filterList = useMemo(
    () =>
      isAdditionalStep ? filters : (
        Array.from(filters).sort(
          (prev, next) => +selectedFilters.includes(next.id) - +selectedFilters.includes(prev.id),
        )
      ),
    [filters, selectedFilters, isAdditionalStep],
  );

  return (
    <Wrapper
      chipsCanBeExtended={filters.length > MAX_CHIPS_VALUE}
      filterHandler={handleChange}
      isOpened={isOpened}
      list={filterList}
      onClick={withoutWrapper ? undefined : onClick}
      selected={selectedFilters}
      title={withoutWrapper ? undefined : title}
    >
      <ShowMoreButton className={cx('container')} isMobile={isMobile} list={filterList}>
        {(filterItem) => (
          <div className={cx('checkbox', { mobile: isMobile })} key={filterItem.id}>
            <FilterBlockWithAnalytics name={name}>
              <Checkbox
                checked={selectedFilters.includes(filterItem.id)}
                label={filterItem.name}
                name={filterItem.id}
                onChange={handleChange}
                size="small"
              />
            </FilterBlockWithAnalytics>
            {filterItem.value && (
              <Text color="grey60" size="size6-r">
                {filterItem.value}
              </Text>
            )}
          </div>
        )}
      </ShowMoreButton>
    </Wrapper>
  );
};

_FiltersBlock.propTypes = {
  setFilters: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.array,
  selectedFilters: PropTypes.arrayOf(string),
  onClick: PropTypes.func,
  withoutWrapper: PropTypes.bool,
};

const mapDispatchToProps = (dispatch, props) => ({
  setFilters: (filters) => dispatch(setParametersAction(props.name, filters)),
  setTempFilter: (filters) => dispatch(setTemporaryFilter(filters)),
  onClick: (type) => dispatch(setOpenAdditionalFiltersModal(type)),
});

const mapStateToProps = (state, props) => ({
  selectedFilters: getCurrentFilters(state, props.name),
  isMainStep: !getOpenedFilterType(state),
});
export const FiltersBlock = connect(mapStateToProps, mapDispatchToProps)(_FiltersBlock);
