import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { LayoutGroup, motion } from 'framer-motion';

import { Bonus, TBonus } from '../Bonus';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TBonusMapper = {
  bonuses?: TBonus[];
};

/** Общий компонент бонусов, получаемых из пресетов */
export const BonusMapper: FC<TBonusMapper> = ({ bonuses = [] }) =>
  bonuses?.length ?
    <motion.ul className={cx('wrapper')} layout>
      <LayoutGroup>
        {bonuses.map((bonus) => (
          <Bonus {...bonus} key={bonus.subTitle} />
        ))}
      </LayoutGroup>
    </motion.ul>
  : null;
