import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Link, SearchIcon } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import { CustomPropTypes } from '@beef/react';
import { connect } from 'react-redux';

import { getMaxCountToShowInFilter } from 'pages/SearchResultsPage/store/selectors';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const _ShowMoreButton = ({
  children,
  list,
  className,
  maxCountToShowInFilter,
  withSearch,
  isMobile,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const items = useMemo(
    () => list.filter((item) => item.name.toLowerCase().includes(searchValue.trim())),
    [searchValue, list],
  );

  const showMore = useMemo(
    () => items?.length > maxCountToShowInFilter && !isMobile,
    [items?.length, maxCountToShowInFilter, isMobile],
  );
  const [opened, setOpened] = useState(!showMore);
  const showInput = withSearch && (isMobile || opened);
  const showedItems =
    opened || !showMore || isMobile ? items : items.slice(0, maxCountToShowInFilter);

  const handleChangeSearchInput = (e) => {
    setSearchValue(e ? e.target.value.toLowerCase() : '');
  };

  const handleClick = () => {
    setOpened((prev) => !prev);
  };

  return (
    <>
      {showInput && (
        <div className={cx('search')}>
          <Input
            autoComplete="off"
            leftAside={<SearchIcon fill="#BEBEC9" size="s" />}
            noErrorIndent
            onChange={handleChangeSearchInput}
            onClear={() => handleChangeSearchInput()}
            placeholder="Поиск"
            type="text"
            value={searchValue}
          />
        </div>
      )}
      <div className={cx({ 'scroll-container': showMore || isMobile }, className)}>
        {showedItems.map(children)}
      </div>
      {showMore && (
        <div className={cx('link')}>
          <Link isNoUnderline onClick={handleClick} size="size6-m">
            {opened ? 'Свернуть' : 'Смотреть все'}
          </Link>
        </div>
      )}
    </>
  );
};

_ShowMoreButton.propTypes = {
  children: CustomPropTypes.component,
  // eslint-disable-next-line react/forbid-prop-types
  list: PropTypes.array,
  className: PropTypes.string,
  maxCountToShowInFilter: PropTypes.number,
  isMobile: PropTypes.bool,
};

const mapStateToProps = (state, props) => ({
  maxCountToShowInFilter: getMaxCountToShowInFilter(state),
  withSearch: props.list?.length > getMaxCountToShowInFilter(state),
});

export const ShowMoreButton = connect(mapStateToProps)(_ShowMoreButton);
