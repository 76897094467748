import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { apiGetFmcServices } from './constants';
import { TServicesTariff } from '../store/slices/fttbSlice/types';

/** Тип параметров запроса метода */
type TFetchFmcServicesReq = {
  generation: number;
  highSpeedAvailable: number;
  isConnected: boolean;
  priceCategory?: string;
  regionId: number;
  soc: string;
};

/** Тип ответа метода '/fmc-services/' */
export type TFetchFmcServicesQueryRes = {
  isSuccess?: boolean;
  tariffs?: TServicesTariff[];
};

/** Запрос данных для настройки по выбранной скорости пресета ШПД на главной странице конструктора */
export const fetchFmcServices = createAsyncThunk<TFetchFmcServicesQueryRes, TFetchFmcServicesReq>(
  'presetApi/fetchFmcServices',
  async (params, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const response = (
        await axios.get<TFetchFmcServicesQueryRes>(apiGetFmcServices, {
          params,
          cancelToken: source.token,
        })
      ).data;

      /* Если ответ от бека содержит !isSuccess,
       выкидываем ошибку (покажем блок повторного запроса ШПД-пресетов ) */
      if (!response?.isSuccess) {
        throw new Error();
      }
      return response;
    } catch (e) {
      throw new Error();
    }
  },
);
