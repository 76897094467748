import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { TFetchPacketsRes, fetchPackets } from '../../../api/fetchPackets';

/** Тип параметров запроса fetchPackets пакетов ConstructorV1 */
type TFetchPacketsReq = {
  soc: string;
};

/** Обертка запроса мобильных пакетов ConstructorV1 */
export const getPackets = createAsyncThunk<Partial<TFetchPacketsRes>, TFetchPacketsReq>(
  '/api/v1/constructors/getPackets',
  async (params, { signal }) => {
    try {
      const { token: cancelToken, cancel } = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        cancel();
      });
      return (await fetchPackets({ params, cancelToken })).data;
    } catch (error) {
      throw new Error();
    }
  },
);
