import React, { useRef } from 'react';
import { PageFixer, PageRow } from '@beef/smart-kit';
import { connect } from 'react-redux';
import { compose, withReduxStore } from '@beef/redux';
import PropTypes from 'prop-types';
import { withDataConverter } from '@beef/react';

import { NotFound } from 'pages/SearchResultsPage/components/NotFound';
import { LinkShowAllCategory } from 'pages/SearchResultsPage/components/LinkShowAllCategory';
import { DesktopCategories } from 'pages/SearchResultsPage/components/Filter/Categories';
import { Price } from 'pages/SearchResultsPage/components/Filter/Price';
import { FilterLabel } from 'pages/SearchResultsPage/components/Filter/FilterLabel';
import { useInitialRequest } from 'pages/SearchResultsPage/hooks/useInitialRequest';
import { NotFoundWithAnalytics } from 'pages/SearchResultsPage/components/NotFound/NotFoundWithAnalytics';
import { PriceWithAnalytics } from 'pages/SearchResultsPage/components/Filter/Price/PriceWithAnalytics';
import { Preload } from 'pages/SearchResultsPage/components/Preload';
import { FiltersMapper } from 'pages/SearchResultsPage/components/Filter/FiltersMapper';
import { SmoothContentSwitcher } from 'pages/SearchResultsPage/components/SmoothContentSwitcher';
import { ListenerCorrection } from 'pages/SearchResultsPage/components/ListenerCorrection';
import {
  getIsOpenDeviceModal,
  getIsSelectElkCategory,
  getIsWithDescription,
  getTitleText,
} from 'pages/SearchResultsPage/store/selectors/products';
import { NOT_FOUND_TEXT } from 'pages/SearchResultsPage/utils/constants';

import { DeviceList } from './components/DeviceList';
import { SpecialCategories } from './components/SpecialCategories';
import { Taps } from './components/Taps';
import { Title } from './components/Title';
import { Settings } from './components/Settings';
import { clearFilter, initialSearchParams } from './store/actions';
import {
  getCorrection,
  getIsArticles,
  getIsInitialLoading,
  getIsLoading,
  getIsSelectServiceCategory,
  getProductList,
  getSelectedFiltersCountMobile,
  getTotalHits,
  isNotFoundProducts,
} from './store/selectors';
import { ArticlesList } from './components/ArticlesList';
import { initializeStore } from './store';
import { Pagination } from './components/Pagination';
import { ColumnsLayout } from './components/ColumnsLayout';
import { dataConverter } from './store/dataConverter';

const Search = ({
  isArticles,
  isNotFound,
  onSetInitialParams,
  totalHits,
  isLoading,
  isCategoriesSelected,
  isInitialLoading,
  devices,
  isShopModalEnabled,
  correction,
  title,
  withDescription,
  resetFilter,
}) => {
  const shouldRenderProducts = !isArticles && !isNotFound;
  const deviceListRef = useRef();
  useInitialRequest(onSetInitialParams);

  if (isInitialLoading) {
    return (
      <PageFixer size="m">
        <Preload />
      </PageFixer>
    );
  }

  return (
    <PageFixer size="m">
      <PageRow blockSpacersPreset="m">
        <ListenerCorrection correction={correction} />
        <LinkShowAllCategory />
        <Title title={title} withDescription={withDescription} />
        <SpecialCategories />
        {isNotFound && (
          <NotFoundWithAnalytics>
            <NotFound text="Попробуйте сократить запрос или задать его по-другому" />
          </NotFoundWithAnalytics>
        )}
        {shouldRenderProducts && (
          <ColumnsLayout
            leftColumn={
              <>
                <DesktopCategories />
                <FilterLabel />
                <PriceWithAnalytics>
                  <Price />
                </PriceWithAnalytics>
                <FiltersMapper />
              </>
            }
            ref={deviceListRef}
            rightColumn={
              <>
                <Settings />
                {totalHits ?
                  <>
                    <Taps />
                    <SmoothContentSwitcher isLoading={isLoading}>
                      <DeviceList
                        devices={devices}
                        isCategoriesSelected={isCategoriesSelected}
                        isShopModalEnabled={isShopModalEnabled}
                      />
                    </SmoothContentSwitcher>
                    <Pagination deviceListRef={deviceListRef} />
                  </>
                : !isLoading && (
                    <NotFoundWithAnalytics>
                      <NotFound resetFilter={resetFilter} text={NOT_FOUND_TEXT} withFilters />
                    </NotFoundWithAnalytics>
                  )
                }
              </>
            }
          />
        )}
        <ArticlesList />
      </PageRow>
    </PageFixer>
  );
};

Search.propTypes = {
  isArticles: PropTypes.bool,
  onSetInitialParams: PropTypes.func,
  resetFilter: PropTypes.func,
  isNotFound: PropTypes.bool,
  totalHits: PropTypes.number,
  isLoading: PropTypes.bool,
  isShopModalEnabled: PropTypes.bool,
  isCategoriesSelected: PropTypes.bool,
  isInitialLoading: PropTypes.bool,
  title: PropTypes.string,
  withDescription: PropTypes.bool,
  devices: PropTypes.arrayOf({}),
  correction: PropTypes.string,
};

const mapStateToProps = (state) => ({
  correction: getCorrection(state),
  isLoading: getIsLoading(state),
  totalHits: getTotalHits(state),
  isArticles: getIsArticles(state),
  isNotFound:
    isNotFoundProducts(state) && !getSelectedFiltersCountMobile(state) && !getIsArticles(state),
  devices: getProductList(state),
  isInitialLoading: getIsInitialLoading(state),
  isCategoriesSelected: getIsSelectServiceCategory(state) || getIsSelectElkCategory(state),
  isShopModalEnabled: getIsOpenDeviceModal(state),
  title: getTitleText(state),
  withDescription: getIsWithDescription(state),
});

export const SearchResultsPage = compose(
  withDataConverter(dataConverter),
  withReduxStore(initializeStore),
  connect(mapStateToProps, { onSetInitialParams: initialSearchParams, resetFilter: clearFilter }),
)(Search);
