import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { TPreChangesInfoItem } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/components/constructorPage/PreChangesTariffContainer/components/PreChangesInfo/components/PreChangesInfoItem';

import { apiGetTariffChangesUrl } from './constants';

/** Тип запроса (params) */
type TFetchTariffChangesReq = {
  characterSoc: string;
  gbValue: number | string;
  generation: number;
  minValue: number;
  optionsSoc: string;
  priceCategory: string;
  servicesInacIdList?: string;
  tariffSoc: string;
  updatedNetSpeed: null | string;
};

type TPreChangeButton = {
  link?: string;
  text: string;
};

/** Тип data содержит changes (что изменится) и informationConflicts (например "Отключится скидка") */
type TData = {
  changes: TPreChangesInfoItem[];
  informationConflicts: TPreChangesInfoItem[];
} | null;

/** Тип терминальных конфликтов */
export type TTerminalConflict = {
  button: TPreChangeButton;
  buttonExtra?: TPreChangeButton;
  description: string;
  icon: string;
  title: string;
};

/** Тип ошибки подключения (пока что только терминальные конфликты) */
type TErrors = {
  terminalConflict: TTerminalConflict;
};

/** Тип ответа */
export type TFetchTariffChangesQueryRes = {
  data: TData;
  errors?: TErrors;
  /* Параметр указывает на то, что на этом шаге при клике по кнопке вызывается калбек */
  isPreChangeCallback: boolean;
  isSuccess: boolean;
};

/** Запрос данных по изменениям подключенного тарифа */
export const fetchTariffChanges = createAsyncThunk<
  TFetchTariffChangesQueryRes,
  TFetchTariffChangesReq
>('changesApi/fetchTariffChanges', async (params, { signal, rejectWithValue }) => {
  try {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });
    const response = (
      await axios.get<TFetchTariffChangesQueryRes>(apiGetTariffChangesUrl, {
        params,
        cancelToken: source.token,
      })
    ).data;

    /* Если ответ от бека содержит !isSuccess выкидываем ошибку (отрисуется блок ошибки) */
    if (!response?.isSuccess) {
      throw new Error();
    }

    return response;
  } catch (e) {
    return rejectWithValue({ isSuccess: false, data: null });
  }
});
