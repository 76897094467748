import React from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';
import { Provider, connect } from 'react-redux';

import store from 'store';
import { getQuerySelector } from 'pages/SearchResultsPage/store/selectors/search';
import { prepareQuerySearch } from 'pages/SearchResultsPage/utils/helperFilters';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TitlePropsType = {
  query: string;
  title: string;
  withDescription: boolean;
};

const _Title = ({ title, query = '', withDescription }: TitlePropsType) => (
  <div className={cx('container')}>
    <Text size="size1-m" tagType="h1">
      {title}
    </Text>
    {withDescription && query && (
      <Text className={cx('description')} color="grey60" size="size4-r">
        Результаты по запросу «{prepareQuerySearch(query)}»
      </Text>
    )}
  </div>
);

export const ConnectedComponent = connect((state) => ({ query: getQuerySelector(state) }))(_Title);

export const Title = (props: TitlePropsType) => (
  <Provider store={store}>
    <ConnectedComponent {...props} />
  </Provider>
);
