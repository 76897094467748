import { createReducer } from '@reduxjs/toolkit';

import { defaultParameters } from 'pages/SearchResultsPage/utils/constants';

import {
  resetParametersFilters,
  setArticles,
  setCorrection,
  setCurrentUrl,
  setData,
  setFilters,
  setInitialParameters,
  setInputPriceValue,
  setIsArticles,
  setIsInitialLoading,
  setIsLoading,
  setIsResetAvailableParameters,
  setOpenFilterType,
  setParameters,
  setTemporaryFilter,
  setTerm,
} from '../actions/products';

export const products = createReducer(
  {
    searchUrl: '',
    autocompleteUrl: '',
    webViewSearchUrl: '',
    webViewAutocompleteUrl: '',
    maxCountToShowInFilter: 7,
    maxCountToOpenFilter: 3,
    defaultImg: null,
    articles: [],
    taps: [],
    productList: [],
    isLoading: true,
    isInitialLoading: true,
    totalHits: null,
    openedFilterType: null,
    inputPrice: { min: '', max: '' },
    selectedCategories: [],
    isAvailableParameters: false,
    availableParameters: {
      categories: [],
      price: { min: 0, max: 999999 },
      filters: {},
    },
    isArticles: false,
    selectedParameters: { ...defaultParameters },
    temporaryFilter: [],
  },
  {
    [setIsLoading.type]: (state, action) => {
      state.isLoading = action.payload;
    },
    [setCurrentUrl.type]: (state, action) => {
      const isWebview = action.payload;
      if (isWebview) {
        state.autocompleteUrl = state.webViewAutocompleteUrl;
        state.searchUrl = state.webViewSearchUrl;
      }
    },
    [setIsInitialLoading.type]: (state, action) => {
      state.isInitialLoading = action.payload;
    },
    [setCorrection.type]: (state, action) => {
      state.correction = action.payload;
    },
    [setData.type]: (state, action) => {
      // eslint-disable-next-line prefer-const
      const { selectedCategories, productList, categories, price, totalHits, filters, correction } =
        action.payload;
      state.selectedCategories = selectedCategories;
      state.correction = correction;
      state.availableParameters = { categories, price, filters };
      state.totalHits = totalHits;
      state.productList = productList;
      if (state.articles?.length > 0) {
        categories.push({
          id: 'articles',
          name: 'Статьи',
          pictureUrl: state.defaultImg?.articles?.tabImg,
          value: state.articles?.length,
        });
      }
    },
    [setIsResetAvailableParameters.type]: (state, action) => {
      state.isAvailableParameters = action.payload;
    },
    [setArticles.type]: (state, action) => {
      state.articles = action.payload.articles;
      state.taps = action.payload.taps;
    },
    [setParameters.type]: (state, action) => {
      const { categories, ...parameters } = action.payload;
      if (categories === 'articles') {
        state.isArticles = true;
        state.selectedParameters = { ...state.selectedParameters, ...parameters };
      } else {
        state.isArticles = false;
        state.selectedParameters = { ...state.selectedParameters, ...action.payload };
      }
    },
    [setOpenFilterType.type]: (state, action) => {
      const type = action.payload;
      state.openedFilterType = type;
      state.temporaryFilter = state.selectedParameters.filters?.[type]?.split(';') || [];
    },
    [setFilters.type]: (state, action) => {
      state.selectedParameters = {
        ...state.selectedParameters,
        filters: { ...state.selectedParameters.filters, ...action.payload },
      };
    },
    [setTemporaryFilter.type]: (state, action) => {
      state.temporaryFilter = action.payload;
    },
    [setInputPriceValue.type]: (state, action) => {
      state.inputPrice = action.payload;
    },
    [setInitialParameters.type]: (state, action) => {
      const { categories, ...parameters } = action.payload;
      if (categories === 'articles') {
        state.isArticles = true;
        state.selectedParameters = { ...defaultParameters, ...parameters };
      } else {
        state.isArticles = false;
        state.selectedParameters = { ...defaultParameters, ...action.payload };
      }
      state.availableParameters.brands = [];
    },
    [setTerm.type]: (state, action) => {
      state.selectedParameters.term = action.payload;
    },
    [setIsArticles.type]: (state, action) => {
      state.isArticles = action.payload;
    },
    [resetParametersFilters.type]: (state) => {
      state.availableParameters = {
        categories: state.availableParameters.categories,
        price: state.availableParameters.price,
        filters: {},
      };
      state.productList = [];
      state.selectedCategories = [];
      state.totalHits = 0;
    },
  },
);
