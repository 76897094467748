import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { formatOrderData } from '@beef/layout-kit/utils';

import { fetchBasket } from 'constructorV1/api/fetchBasket';

export type TBasketQueryReq = ReturnType<typeof formatOrderData>;

export type TBasketQueryRes = Record<string, any>;

export const basketQuery = createAsyncThunk<TBasketQueryRes, TBasketQueryReq>(
  '/fetchBasket',
  async (params, { signal, rejectWithValue }) => {
    const { apiEndpoint, body } = params;
    try {
      const { token: cancelToken, cancel } = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        cancel();
      });
      return (await fetchBasket(apiEndpoint, body, { cancelToken })).data;
    } catch (e) {
      rejectWithValue({});
      return {};
    }
  },
);
