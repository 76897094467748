import axios from 'axios';
import qs from 'query-string';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiSubmitCallbackRequest } from './constants';
import { CALLBACK_CASE_TILE_SALE, CALLBACK_CASE_TILE_SUPPORT } from '../constants';

/** Тип параметров CaseTile */
type TCaseTile = typeof CALLBACK_CASE_TILE_SALE | typeof CALLBACK_CASE_TILE_SUPPORT;

/** Тип параметров запроса метода */
type TRequestParams = {
  caseTile?: TCaseTile;
  phoneNumber: string;
};

/** Тип ответа метода */
export type TSubmitCallbackRequest = {
  isCaptchaError?: boolean;
  isSuccess?: boolean;
  shouldShowCaptcha?: boolean;
};

/** Запрос данных для настройки по выбранной скорости пресета ШПД на главной странице конструктора */
export const fetchSubmitCallbackRequest = createAsyncThunk<TSubmitCallbackRequest, TRequestParams>(
  'presetApi/submitCallbackRequest',
  async ({ phoneNumber, caseTile = CALLBACK_CASE_TILE_SUPPORT }, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const requestObj = {
        phoneNumber: `+7${phoneNumber}`,
        caseTitle: caseTile,
      };

      const requestConfig = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'X-Requested-With': 'XMLHttpRequest',
        },
      };

      const requestData = qs.stringify(requestObj);

      return await axios
        .post(apiSubmitCallbackRequest, requestData, requestConfig)
        .then(({ data }) => {
          /* TODO: добавить поддержку каптчи */
          const { isSucceeded, isCaptchaRequired, isCaptchaError } = data;

          if (!isSucceeded) {
            throw new Error();
          }

          return {
            isSuccess: isSucceeded,
            isCaptchaRequired,
            isCaptchaError,
          };
        });
    } catch (e) {
      throw new Error();
    }
  },
);
