import React, { FC, Fragment } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { setParametersAction } from 'pages/SearchResultsPage/store/actions';
import { getCategories, getCurrentCategory } from 'pages/SearchResultsPage/store/selectors';
import { CategoriesWithAnalytics } from 'pages/SearchResultsPage/components/Filter/Categories/CategoriesWithAnalytics';
import { CategoryLink } from 'pages/SearchResultsPage/components/Filter/CategoryLink';
import { CategoryPropsType } from 'pages/SearchResultsPage/components/Filter/Categories/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const _Category: FC<CategoryPropsType> = ({
  categories,
  setFilter,
  selectedId,
  showAllCategoriesLink,
}) => (
  <>
    {categories.map(({ id, value, name, children }) => (
      <Fragment key={id}>
        <CategoriesWithAnalytics>
          <CategoryLink
            isOpen={showAllCategoriesLink && children?.length > 0}
            isSelectedCategory={selectedId === id}
            name={name}
            onClick={() => setFilter(id)}
            value={value}
          />
        </CategoriesWithAnalytics>
        {showAllCategoriesLink && children?.length > 0 && (
          <div className={cx('children')}>
            <DesktopCategory categories={children} />
          </div>
        )}
      </Fragment>
    ))}
  </>
);

const mapDispatchToProps = {
  setFilter: (value: string) => setParametersAction('categories', value || ''),
};

// @ts-expect-error state not typed in ts yet
const mapStateToProps = (state) => ({
  selectedId: getCurrentCategory(state),
  showAllCategoriesLink: getCurrentCategory(state) && !!getCategories(state).length,
});

export const DesktopCategory = connect(mapStateToProps, mapDispatchToProps)(_Category);
