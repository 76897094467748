import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Button, ChevronLeftIcon, CloseIcon, Text } from '@beef/ui-kit';
import { connect } from 'react-redux';

import {
  getOpenedFilterType,
  getSelectedFiltersCountMobile,
} from 'pages/SearchResultsPage/store/selectors';
import { setOpenFilterType } from 'pages/SearchResultsPage/store/actions';

import { ResetButtonWithAnalytics } from '../../../../../Filter/FilterLabel/ResetButtonWithAnalytics';
import { ResetButton } from './ResetButton';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const _SidebarHeader = ({ onClose, showClear, onClear, onReturn, showReturn, showClose }) => (
  <div className={cx('wrapper')}>
    <div>
      {showClear && (
        <ResetButtonWithAnalytics>
          <ResetButton onClick={onClear} />
        </ResetButtonWithAnalytics>
      )}
      {showReturn && (
        <Button onClick={onReturn} rightIcon={<ChevronLeftIcon size={20} />} variant="plain" />
      )}
    </div>
    <div className={cx('title')}>
      <Text size="size4-m">Фильтры</Text>
    </div>
    {showClose && <Button onClick={onClose} rightIcon={<CloseIcon size={20} />} variant="plain" />}
  </div>
);

_SidebarHeader.propTypes = {
  showClear: PropTypes.bool,
  showReturn: PropTypes.bool,
  showClose: PropTypes.bool,
  onClose: PropTypes.func,
  onClear: PropTypes.func,
  onReturn: PropTypes.func,
};

const mapStateToProps = (state) => ({
  showReturn: getOpenedFilterType(state),
  showClear: !!getSelectedFiltersCountMobile(state) && !getOpenedFilterType(state),
  showClose: !getOpenedFilterType(state),
});

const mapDispatchToProps = (dispatch) => ({
  onReturn: () => dispatch(setOpenFilterType(null)),
});

export const SidebarHeader = connect(mapStateToProps, mapDispatchToProps)(_SidebarHeader);
