export const TEMPLATE_BTN_FILTERS = '{{action}} {{count}} {{textProducts}}';
export const TITLE_FILTERS = 'Фильтры';
export enum TEXT_BTN_FILTERS {
  show = 'Показать',
  applyFilters = 'Применить фильтры',
}

export enum TYPE_METHOD {
  open = 'open',
  close = 'close',
  apply = 'apply',
  clear = 'clear',
}

export const enum CATEGORIES {
  article = 'Статья',
  elk = '1540',
  service = '1265',
  tariff = '101',
}

export const CATEGORY_BY_ID = {
  1265: 'services',
  1540: 'elk',
  101: 'tariffs',
  articles: 'articles',
};
