import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { TChangePacketRes, fetchChangePacket } from 'constructorV1/api/fetchChangePacket';

/** Тип параметров запроса fetchChangePackets пакетов ConstructorV1 */
type TChangePacketReq = {
  gbValue: number;
  minValue: number;
  signal: AbortSignal;
  soc: string;
};

/** Обертка запроса изменения мобильных пакетов ConstructorV1 */
export const changePacket = createAsyncThunk<Partial<TChangePacketRes>, TChangePacketReq>(
  '/api/v1/constructors/changePacket',
  async ({ signal, ...params }) => {
    try {
      const { token: cancelToken, cancel } = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        cancel();
      });
      return (await fetchChangePacket({ params, cancelToken })).data;
    } catch (e) {
      throw new Error();
    }
  },
);
