import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { CategoryLink } from 'pages/SearchResultsPage/components/Filter/CategoryLink';

import { CategoriesPropsType } from '../types';
import { withConnectCategories } from '../hocs/withConnectCategories';
import styles from './styles.pcss';
import { DesktopCategory } from './DesktopCategory';

const cx = classNames.bind(styles);

const _DesktopCategories: FC<CategoriesPropsType> = ({
  categories,
  resetFilter,
  isShowReturnLink,
}) => (
  <>
    {isShowReturnLink && (
      <CategoryLink isOpen name="Во все категории" onClick={resetFilter} withIcon />
    )}
    <div className={cx('categories', isShowReturnLink && 'categories-with-link')}>
      <DesktopCategory categories={categories} />
    </div>
  </>
);

export const DesktopCategories = withConnectCategories(_DesktopCategories);
