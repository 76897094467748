import { emptyArr, emptyObj } from '@beef/utils';
import { createSelector } from '@reduxjs/toolkit';

import { SEARCH_TITLE } from 'pages/SearchResultsPage/utils/constants';
import { FILTER_NAMES, TEXT_BRAND } from 'pages/SearchResultsPage/utils/constsFilters';
import { CATEGORIES, CATEGORY_BY_ID } from 'pages/SearchResultsPage/store/constants';

const getProductsState = (state) => state.products;
const getAvailableParameters = (state) => getProductsState(state)?.availableParameters || emptyObj;
export const getMaxCountToShowInFilter = (state) => getProductsState(state).maxCountToShowInFilter;
export const getMaxCountToOpenFilter = (state) => getProductsState(state).maxCountToOpenFilter - 1;
export const getIsArticles = (state) => getProductsState(state).isArticles;
export const getOpenedFilterType = (state) => getProductsState(state).openedFilterType;
export const getSelectedParameters = (state) =>
  getProductsState(state)?.selectedParameters || emptyObj;
export const getPriceCategories = (state) => getAvailableParameters(state).price;
export const getTemporaryFilter = (state) => getProductsState(state).temporaryFilter || emptyArr;

export const getIsSelectServiceCategory = createSelector(
  getProductsState,
  (state) => state.selectedParameters.categories === state.defaultImg.services.id,
);

export const getIsSelectElkCategory = createSelector(
  getProductsState,
  (state) => state.selectedParameters.categories === state.defaultImg.elk.id,
);

export const getTaps = (state) => getProductsState(state).taps;

export const getProductList = createSelector(getProductsState, (state) =>
  state.productList.map((device) => {
    let src = device.image_url;
    const isTariff = device.categories?.[0]?.id === CATEGORIES.tariff;
    const isServices = device.categories?.[0]?.id === CATEGORIES.service;
    const isElk = device.categories?.[0]?.id === CATEGORIES.elk;
    const slug = device.link_url.match(/details\/(.+[^/])\/?$/)?.[1];
    const isPreorder = device.attributes?.['предзаказ']?.[0] === 'true';
    const price = device?.attributes?.bnpl_sum?.[0];
    const count = device?.attributes?.bnpl_times?.[0];
    const text = device?.attributes?.bnpl_text?.[0];
    const isBnpl = price && count && text;

    if (!src) {
      if (isTariff) {
        src = state.defaultImg?.tariffs?.defaultImg;
      } else if (isServices) {
        src = state.defaultImg?.services?.defaultImg;
      } else if (isElk) {
        src = state.defaultImg?.elk?.defaultImg;
      }
    }

    return {
      id: device.id || device.ids[0],
      imgOptions: [{ src }],
      isTariff,
      isServices,
      isElk,
      link: device.link_url,
      slug,
      name: device.name,
      price: device.price,
      oldPrice: device.oldPrice,
      brand: device.brand,
      attributes: device.attributes,
      promoBadges: device.attributes?.['бэйджи']?.map((bagde) => ({ children: bagde })),
      isPreorder,
      installments:
        isBnpl ?
          [
            {
              type: 'bnpl',
              count,
              price,
              text,
            },
          ]
        : [],
    };
  }),
);

export const getArticles = createSelector(getProductsState, (state) => state.articles);
export const getIsLoading = (state) => getProductsState(state).isLoading;
export const getIsInitialLoading = (state) => getProductsState(state).isInitialLoading;
export const getTotalHits = (state) => getProductsState(state).totalHits;
export const getCorrection = (state) => getProductsState(state).correction;
export const getFilters = (state) => getAvailableParameters(state).filters;
export const getIsOpenDeviceModal = (state) => getProductsState(state).isShopModalEnabled;

const getAvailableParametersCategories = (state) =>
  state.availableParameters?.categories || emptyArr;
const getSelectedParametersCategories = (state) => state.selectedParameters?.categories || emptyArr;

const getSelectedCategory = (state) =>
  state.availableParameters.categories.find(
    (item) => item.id === state.selectedParameters.categories,
  );

const getCurrentCategories = createSelector(
  getSelectedCategory,
  getAvailableParametersCategories,
  getSelectedParametersCategories,
  (selectedCategory, availableParameters, selectedParameters) => {
    if (!selectedParameters.length) {
      return availableParameters;
    }
    return selectedCategory ? [selectedCategory] : [];
  },
);

export const getCategories = createSelector(getProductsState, (state) =>
  state.selectedCategories?.length ? state.selectedCategories : getCurrentCategories(state),
);

export const getSpecialCategories = createSelector(getProductsState, (state) => {
  const currentCategories =
    !state.selectedParameters.categories ? state.availableParameters.categories : [];
  if (currentCategories.length) {
    return currentCategories.map((category) => {
      const type = CATEGORY_BY_ID[category.id];
      if (!category?.pictureUrl && type) {
        return { ...category, pictureUrl: state.defaultImg?.[type]?.tabImg };
      }
      return category;
    });
  }
  return currentCategories;
});

export const getTotal = createSelector(getProductsState, (state) =>
  Math.ceil(+state.totalHits / +state.selectedParameters.size),
);

export const isNotFoundProducts = createSelector(
  getProductsState,
  (state) => !state.totalHits && !state.isLoading,
);

export const getFiltersIsEnabled = createSelector(
  getProductsState,
  (state) =>
    !!state.availableParameters.price ||
    state.selectedParameters.price ||
    !!Object.keys(state.availableParameters?.filters || {}).length,
);

export const getPriceInputValue = (state) => getProductsState(state).inputPrice;

export const getTypeFilter = createSelector(getOpenedFilterType, (type) =>
  type === TEXT_BRAND ? FILTER_NAMES.brands : type,
);

export const getCurrentFilters = (state, key) => {
  const temporaryFilters = getTemporaryFilter(state);
  const params = getSelectedParameters(state);
  const isAdditionalStep = !!getOpenedFilterType(state);

  if (isAdditionalStep) {
    return [...temporaryFilters];
  }

  const filters = params.filters?.[key]?.split(';');
  return filters?.[0] ? filters : [];
};

export const getSort = (state) => getSelectedParameters(state).sort;
export const getTerm = (state) => getSelectedParameters(state).term;
export const getCurrentPage = createSelector(getSelectedParameters, (state) =>
  state.offset ? (+state.offset + +state.size) / +state.size : 1,
);
export const getOffset = (state) => getSelectedParameters(state).offset;
export const getLimit = (state) => getSelectedParameters(state).size;
export const isSelectedCategory = createSelector(
  getProductsState,
  (state) => !!state.selectedCategories.length,
);
export const getCurrentCategory = (state) => getSelectedParameters(state).categories;

export const getShowReturnLink = createSelector(
  getProductsState,
  getCurrentCategory,
  (product, currentCategory) =>
    currentCategory && product.availableParameters.categories.length > 1,
);

export const getSelectedFiltersCount = createSelector(getSelectedParameters, (state) => {
  let count = state.price ? 1 : 0;
  if (state?.filters) {
    Object.keys(state.filters).forEach((key) => {
      if (state.filters[key]) {
        count += 1;
      }
    });
  }
  return count;
});

export const getSelectedFiltersCountMobile = createSelector(getSelectedParameters, (state) => {
  let count = state.price ? 1 : 0;
  count = state.categories ? count + 1 : count;
  if (state?.filters) {
    Object.keys(state.filters).forEach((key) => {
      if (state.filters[key]) {
        count += 1;
      }
    });
  }
  return count;
});

export const getTitleText = createSelector(getProductsState, getProductList, (state, list) => {
  const selectedCategory = getSelectedCategory(state);
  const id = state.selectedParameters.categories;
  if (state.isArticles) {
    return SEARCH_TITLE.articles;
  }
  if (selectedCategory?.name) {
    return selectedCategory.name;
  }
  if (state.selectedCategories?.[0]?.id === id) {
    return state.selectedCategories?.[0]?.name;
  }
  if (state.selectedCategories?.[0]?.children?.[0]?.id === id) {
    return state.selectedCategories?.[0]?.children?.[0]?.name;
  }
  if (state.availableParameters?.categories?.length && list.length) {
    return SEARCH_TITLE.categories;
  }
  return SEARCH_TITLE.search;
});
export const getIsWithDescription = createSelector(
  getProductsState,
  (state) =>
    !!state.availableParameters?.categories?.length && !state.selectedParameters.categories,
);

export const getPlaceForAnalytics = createSelector(getProductsState, (state) => {
  switch (state.selectedParameters.categories) {
    case state.defaultImg.tariffs.id: {
      return 'tariffs';
    }
    case state.defaultImg.services.id: {
      return 'services';
    }
    case undefined:
    case '': {
      return 'main';
    }
    default: {
      return 'page_products';
    }
  }
});

export const selectBasketIdExpiration = (state) => getProductsState(state).basketIdExpiration;

export const getSelectedFilters = createSelector(
  getSelectedParameters,
  (selectedParameters) => selectedParameters?.filters || {},
);
