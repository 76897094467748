export const textFormsProducts = ['товар', 'товара', 'товаров'];
export const textFormsSearch = ['Найден', 'Найдено', 'Найдено'];

export const dropdownSortOptions = [
  {
    label: 'По популярности',
    slug: 'DEFAULT',
  },
  {
    label: 'Сначала дорогие',
    slug: 'PRICE_DESC',
  },
  {
    label: 'Сначала дешевые',
    slug: 'PRICE_ASC',
  },
];

export const ENTITY_TYPE = {
  dots: 'dots',
  item: 'item',
  prev: 'prev',
  next: 'next',
};

export const defaultParameters = {
  sort: 'DEFAULT',
  categories: null,
  offset: 0,
};

export const SEARCH_TITLE = {
  search: 'Результаты поиска',
  categories: 'Найдено в категориях',
  articles: 'Статьи',
};

export const CATEGORIES = {
  main: 'page_all_categories',
  page_products: 'page_products',
  services: 'page_services',
  tariffs: 'page_tariffs',
  articles: 'page_articles',
};

export const TARGET_CLICK = {
  tap: 'search_suggest_click',
  sort: 'sort_results',
  reset_filter: 'filter_reset',
  category: 'filter_category',
  brand: 'filter_brand',
  article: 'click',
  services: 'click',
  tariffs: 'click',
  products: 'click',
  price: 'filter_price',
  main_category: 'filter_main_category',
};

export const TARGET_VIEW = {
  tap: 'search_suggest_view',
  article: 'view',
  services: 'view',
  tariffs: 'view',
  products: 'view',
};

export const NOT_FOUND_TEXT = 'Нет товаров, удовлетворяющих условиям поиска';
