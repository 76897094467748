import React from 'react';
import { DIMENSION_GROUPS, useGetDimensionGroup } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { Button } from '../Button';
import { BurgerMenu } from '../BurgerMenu';
import { Sidebar } from '../Sidebar';
import { SideBarInner } from '../Sidebar/SideBarInner';
import { HeaderContent } from './HeaderContent';
import { useSideBarOpen } from './hooks/useSideBarOpen';
import styles from './styles.pcss';
import type { THeader } from '../types';

const cx = classNames.bind(styles);

export const Header: React.FC<THeader> = ({ data, isDarkTheme, ym, analyticsEvents, isAuth }) => {
  const currentDimensionGroup = useGetDimensionGroup();
  // set IsMobile mode as default on ssr
  const isMobile = !currentDimensionGroup || currentDimensionGroup === DIMENSION_GROUPS.mobile;
  const [isSideBarOpen, setIsSideBarOpen] = React.useState(false);
  useSideBarOpen(isSideBarOpen);

  const switchSideBar = React.useCallback(() => {
    setIsSideBarOpen((prevState) => !prevState);
  }, [isSideBarOpen, analyticsEvents]);

  return (
    <div className={cx('wrapper')} data-testid="carnica-header">
      <HeaderContent
        analyticsEvents={analyticsEvents}
        isAuth={isAuth}
        isDarkTheme={isDarkTheme}
        isMobile={isMobile}
        isSideBarOpen={isSideBarOpen}
        switchSideBar={switchSideBar}
        ym={ym.headerContent}
      />

      <Sidebar
        hasOverlay
        isOpen={isSideBarOpen}
        onClickOutside={switchSideBar}
        onEsc={switchSideBar}
        position="left"
      >
        <HeaderContent
          analyticsEvents={analyticsEvents}
          isAuth={isAuth}
          isDarkTheme
          isForSideBar
          isMobile={isMobile}
          isSideBarOpen={isSideBarOpen}
          switchSideBar={switchSideBar}
          ym={ym.headerContent}
        />
        <SideBarInner
          {...data}
          analyticsEvents={analyticsEvents}
          closeSideBar={switchSideBar}
          ym={ym.sideBar}
        />
        {!isMobile && (
          <Button className={cx('burger-menu')} color="grey" onClick={switchSideBar} size="s">
            <BurgerMenu
              className={cx({
                'burger-menu--open': isSideBarOpen,
              })}
              color="grey"
            />
          </Button>
        )}
      </Sidebar>
    </div>
  );
};
