import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiConnectTariffWithConvergence } from './constants';

/** Тип параметров запроса метода */
type TFetchTariffWithConvergenceReq = {
  contactPhone?: null | string;
  flat: string;
  houseId: number;
  servicesInacIdList?: string;
};

/** Тип ответа метода */
export type TFetchTariffWithConvergenceRes = {
  /* Доп переменная для синхронизации с бекенд */
  isSucceeded?: boolean;
  isSuccess?: boolean;
};

/** Запрос на подключение конструктора с ШПД в НЗ */
export const fetchTariffWithConvergence = createAsyncThunk<
  TFetchTariffWithConvergenceRes,
  TFetchTariffWithConvergenceReq
>('connectTariffApi/fetchTariffWithConvergence', async (params, { signal, rejectWithValue }) => {
  try {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });
    const response = (
      await axios.post<TFetchTariffWithConvergenceRes>(
        apiConnectTariffWithConvergence,
        params,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        { 'X-Requested-With': 'XMLHttpRequest', cancelToken: source.token },
      )
    ).data;

    /* Если ответ от бека содержит !isSuccess,
       выкидываем ошибку (отрисуется блок ошибки) */
    if (!(response?.isSuccess || response?.isSucceeded)) {
      throw new Error();
    }
    return response;
  } catch (e) {
    return rejectWithValue({ isSuccess: false });
  }
});
