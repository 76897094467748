import React from 'react';
import TopUpBalance, { PaymentMethods, ThreeDSPaymentResult } from '@beep/topup';

import { ActionBar } from './components';

type useTopUpBalanceModalDataProps = {
  payment: {
    paymentMethods: PaymentMethods[];
    paymentParams: {
      amount: {
        max: number;
        min: number;
      };
      widgetPayUrl: string;
    };
  };
  userData: {
    ctn: string | undefined;
    fttb: string | undefined;
    type: 0 | 1;
  };
};

export const useTopUpBalanceModalData = ({
  userData,
  payment: { paymentParams, paymentMethods },
}: useTopUpBalanceModalDataProps) => ({
  modalSize: 'm',
  content: (
    <>
      <ThreeDSPaymentResult onContinue={() => {}} paymentMethods={paymentMethods} />
      <TopUpBalance
        mobileView
        paymentMethods={['fps', 'card', 'apple', 'google', 'sberpay']}
        paymentParams={paymentParams}
        popupContent={{}}
        userInfo={userData}
      />
    </>
  ),
  actionBar: <ActionBar />,
});
