import React from 'react';
import classNames from 'classnames/bind';
import { compose } from '@beef/redux';
import { connect } from 'react-redux';
import { withRenderFlag } from '@beef/react';
import { ChevronLeftIcon, Link } from '@beef/ui-kit';

import { getIsArticles, getProductList } from 'pages/SearchResultsPage/store/selectors';
import { setParametersAction } from 'pages/SearchResultsPage/store/actions';
import { backToSearchResults } from 'pages/SearchResultsPage/utils/analytics';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const _LinkShowAllCategory = ({ setFilter }) => (
  <div className={cx('container')} onClick={backToSearchResults}>
    <Link
      icon={<ChevronLeftIcon fill="#727281" size={20} />}
      isNoUnderline
      onClick={() => setFilter()}
      size="size5-m"
      variant="light"
    >
      Во все категории
    </Link>
  </div>
);

const mapDispatchToProps = (dispatch) => ({
  setFilter: (value) => {
    dispatch(setParametersAction('categories', value || ''));
  },
});

const mapStateToProps = (state) => ({
  shouldRender: getIsArticles(state) && getProductList(state)?.length,
});

export const LinkShowAllCategory = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRenderFlag,
)(_LinkShowAllCategory);
